<script>
import feather from 'feather-icons';
import ProjectSingle from './ProjectSingle.vue';
import projects from '../../data/projects';

export default {
	components: { ProjectSingle},
	data: () => {
		return {
			projects,
			projectsHeading: 'Z życia parafii',
		};
	},
	computed: {
		// Get the filtered projects
		filteredProjects() {
			return this.getLastThreeProjects();
		},
	},
	methods: {
		// Filter projects by title search
		getLastThreeProjects() {
			let startId = this.projects.length - 3;
			
			return this.projects.filter((el) => el.id >= startId);
		},
	},
	mounted() {
		feather.replace();
	},
};
</script>

<template>
	<!-- Projects grid -->
	<section class="pt-10 sm:pt-10 container">
		<!-- Projects grid title -->
		<img class="delimiter mx-auto" src="@/assets/images/delimiter.png"/>
		<div class="text-center pt-10">
			<h2
				class="font-general-semibold text-2xl sm:text-4xl font-semibold mb-2 text-ternary-dark dark:text-ternary-light"
			>
				{{ projectsHeading }}
		</h2>
		</div>

		<!-- Projects grid -->
		<div
			class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mt-6 sm:gap-10 pt-14"
		>
			<ProjectSingle
				v-for="project in filteredProjects"
				:key="project.id"
				:project="project"
			/>
		</div>
	</section>
</template>

<style scoped></style>
