// Projects data.
// Note: Here we are getting data from a js file, but in a different project I'll be fetching these projects from some srt of APIs.

const projects = [
	{
		id: 3,
		title: 'Koncert w 76. rocznicę śmierci biskupa Nathana',
		category: '',
		date: '20.01.2023',
		excerpt: 'W 76. rocznicę śmierci Biskupa Józefa Nathana społeczność branicka zaprasza na koncert...',
		text: `W 76. rocznicę śmierci Biskupa Józefa Nathana społeczność branicka zaprasza na koncert uwielbienia „W tobie jestem wolny”. Występ rozpocznie się w niedzielę (5 lutego) o godzinie 12:00, bezpośrednio po uroczystej mszy świętej w tamtejszym kościele parafialnym.

		W roli wykonawców wystąpią lokalni artyści, kwartet smyczkowy „Appassionato” z Raciborza oraz Dominika Sozańska, Piotr Wiecha i Dominik Wąsik z powiatu głubczyckiego. Za prowadzenie koncertu odpowiadać będzie Urszula Sipel-Wiecha.
		
		Koncert odbędzie się w ramach obchodów 76. rocznicy śmierci biskupa Józefa Nathana, wybitnego duszpasterza, społecznika, założyciela „Miasteczka Miłosierdzia”. Miasteczko to w okresie międzywojennym było jednym z najnowocześniejszych w Europie ośrodków leczenia i terapii osób chorych psychicznie. 
		
		Biskup Nathan zmarł 30 stycznia 1947 roku w Opawie, dokąd został przymusowo wysiedlony przez polskie władze komunistyczne. Doczesne szczątki biskupa sprowadzone zostały do Polski dopiero w roku 2014 i złożone w branickiej świątyni na terenie szpitala.

		Na poziomie diecezjalnym rozpoczęty został proces beatyfikacyjny biskupa. Wybudowany przez niego szpital funkcjonuje do dziś, leczy się w nim około 500 chorych psychicznie, jest to drugi największy szpital na Opolszczyźnie.

		Organizatorami koncertu są Stowarzyszenie Caritas Christi, Specjalistyczny Szpital im. biskupa Józefa Natana i Parafia Świętej Rodziny w Branicach.`,
		img: require('@/assets/images/koncert.jpg'),
		gallery: [],
	},
	{
		id: 2,
		title: 'Dożynki wojewódzkie w Branicach',
		category: '',
		date: '11.09.2022',
		excerpt: 'Do Branic zjechały delegacje z całego regionu. Blisko 40 gmin z Opolszczyzny wysłało...',
		text: `Do Branic zjechały delegacje z całego regionu. Blisko 40 gmin z Opolszczyzny wysłało reprezentacje z tradycyjnymi koronami żniwnymi.`,
		img: require('@/assets/images/dozynki.jpg'),
		gallery: [
			{	
				"id": 1,
				"alt": "",
				"url": require('@/assets/images/dozynki.jpg')
			},
			{
				"id": 2,
				"alt": "",
				"url": require('@/assets/images/korona.jpg'),
			},
			{
				"id": 3,
				"alt": "",
				"url": require('@/assets/images/a1.jpg'),
			},
			{
				"id": 4,
				"alt": "",
				"url": require('@/assets/images/a2.jpg'),
			},
		],
		
	},
	{
		id: 1,
		title: 'Dożynki wojewódzkie 4. września w Branicach',
		category: '',
		date: '04.08.2022',
		excerpt: 'Zapraszamy wszystkich na tegoroczne dożynki wojewódzkie, które odbędą się 4. września...',
		text: `Zapraszamy wszystkich na tegoroczne dożynki wojewódzkie, które odbędą się 4. września w Branicach po dwuletniej, spowodowanej pandemią, przerwie.
			
		Oficjalne uroczystości rozpoczną się o godzinie 10.00 mszą św. dziękczynną w Bazylice Św. Rodziny na terenie szpitala specjalistycznego.
			
		Po Mszy tradycyjny korowód żniwny przejdzie na stadion Orła Branice. Tam prezentacja koron żniwnych. Gala dożynkowa rozpocznie się o godzinie 13.30.
		
		Podczas dożynek rozstrzygnięte zostaną także konkursy:
		<ul>
			<li>Fundusz sołecki – najlepsza inicjatywa;</li>
			<li>Nasze kulinarne dziedzictwo – smaki regionów;</li>
			<li>Wojewódzki konkurs koron dożynkowych;</li>
			<li>Konkurs fotograficzny LGD Płaskowyż Dobrej Ziemi.</li>
		</ul>

		W programie również:
		16.30 – zespół regionalny ISTEBNA
		17.10 – występy artystyczne: HELIZUS, Dominika Sozańska, Amelia Lipa, zespół SZAG, Wiktoria Margiel, Dominik Wąsik, chór „Angelus Cantat”, Marika Pawluś, zespół Zazik
		19.00 – zespół LONG&JUNIOR
		21.00 – zespół POWER PLAY
		22.00 – teatr ognia NAM-TARA
		22.15 – zabawa taneczna

		Gospodarzami dożynek są w tym roku: Marszałek Województwa Opolskiego, Starosta Głubczycki i Wójt Branic.`,
		img: require('@/assets/images/dozynki-1014x576.jpg'),
		gallery: [],
	},
	{
		id: 0,
		title: 'Fragment złotej kraty bazyliki w Branicach odnaleziony!',
		date: '29.07.2022',
		excerpt: 'Fragment złotej kraty bazyliki branickiej – arcydzieła modernizmu europejskiego...',
		text: `Fragment złotej kraty bazyliki branickiej – arcydzieła modernizmu europejskiego – odnaleziony! W uroczystości przywrócenia go na pierwotne miejsce udział wzięli dyrektor MNWr Piotr Oszczanowski i kustosz Jacek Witecki.

		Uroczystość odbyła się w bazylice pw. św. Rodziny w Branicach na Opolszczyźnie, gdzie można podziwiać słynną złotą kratę zaprojektowaną przez Gebharda Utingera.
		
		„Zespół branickich krat powstały w latach 30. XX w. to arcydzieło sztuki modernizmu w europejskiej skali” – mówi kustosz Jacek Witecki. „Zajmowaliśmy się nim szeroko, pracując w 2015 r. nad wystawą »Stwórcze Ręce. Sztuka metalu doby modernizmu we Wrocławiu«. Gebhard Utinger (1879–1960), szwajcarski architekt, malarz i projektant, pracował od 1908 r. we wrocławskiej Szkole Rzemiosł Artystycznych i był związany z Wrocławiem przez ponad 30 lat. Wystrój wnętrz bazyliki i kompleksu klasztorno-szpitalnego w Branicach pozostaje wybitną pamiątką jego twórczości na Śląsku i Opolszczyźnie.
		Kraty branickie wiążą się także z postacią profesora Jaroslava Vonki, działającego we Wrocławiu czeskiego mistrza kowalstwa artystycznego. To jego uczniowie pracowali nad realizacją monumentalnego dzieła”.

		„Była to wspaniała uroczystość. Nam, którym spuścizna po Jaroslavie Vonce i jego kowalskiej szkole szczególnie leży na sercu, udział w tym wydarzeniu sprawił szczególną radość” – powiedział Piotr Oszczanowski, dyrektor MNWr. „Oto fragment niezwykłego dzieła, tzw. złotej kraty bazyliki w Branicach – wspólnej kreacji Gebharda Utingera, Jaroslava Vonki i sześciu młodych mistrzów kowalstwa – został odnaleziony i przywrócony na swoje pierwotne miejsce! My kochamy takie powroty, my radujemy się z takich odkryć! Wielkie słowa uznania dla proboszcza kościoła pw. Świętej Rodziny w Branicach ks. dziekana Alojzego Nowaka oraz dyrektora Specjalistycznego Szpitala im. ks. biskupa Józefa Nathana w Branicach Krzysztofa Nazimka”.`,
		img: require('@/assets/images/Zlota_Krata.jpg'),
		gallery: [],
	}
];

export default projects;
