const projects = [
	{
		id: 2,
		title: '26.02.2023 - I Niedziela Wielkiego Postu',
		date: '11.09.2022',
		text: `<ol>
		<li>Rozpoczął się święty okres <b>Wielkiego Postu</b>. Zachęcamy do praktykowania uczynków pokutnych: postu, modlitwy i jałmużny.<br></li>
		<li>Z jałmużny wielkopostnej zakupimy <b>żywność do paczek dla ubogich osób z parafii</b>. Chcemy dostarczyć żywność przez harcerzy i harcerki w dwóch terminach: <b>20 marca w uroczystość św. Józefa i 5 kwietnia w Wielką Środę.</b> Prosimy parafian o zgłaszanie adresów potrzebujących osób do naszej bazy danych. Bóg zapłać! &nbsp;<br></li>
		<li>Nasza <b>świątynia otwarta jest dla modlitwy</b> przez cały dzień. Obiekt jest <b>monitorowany przez system kamer</b>. W ostatnim czasie zdarzyły się w naszym kościele próby kradzieży. W związku z tym bardzo prosimy wszystkich przebywających na modlitwie o czujność i zwrócenie uwagi na osoby zachowujące się w sposób podejrzany. Taką sytuacje należy natychmiast zgłosić księżom na plebanię.<br></li>
		<li>Zapraszamy na nabożeństwa wielkopostne</span>: <b>Droga Krzyżowa</b> w piątki o godz. 17.00; <b>Gorzkie Żale&nbsp;</b>w niedziele Wielkiego Postu o godz. 17.30.<br> </li>
		<li><em></em><b>Ekstremalna Droga Krzyżowa</b> po raz dziewiąty wyjdzie z Lublina 17 marca. Msza św. rozpoczynająca EDK będzie sprawowana w Archikatedrze Lubelskiej o godz. 19.00 pod przewodnictwem Ks. Abp. Stanisława Budzika. Zapisy trwają na stronie internetowej edk.lublin.pl<br></li>
		<li><em></em>W tym tygodniu obchodzimy<b> I piątek i I sobotę marca</b> - nabożeństwo wynagradzające <b>Najświętszemu Sercu Pana Jezusa</b> i <b>Niepokalanemu Sercu Maryi</b> o godz. 17.00.  Wizytę u chorych w domach można zgłaszać w zakrystii po Mszy Świętej.<br></li>
		<li>W I piątek marca zapraszamy <b>kandydatów do bierzmowania</b> na nabożeństwo pokutne o godz. 17.00, spowiedź od godz. 17.15 i na Msze św. o godz. 18.00.Obecność obowiązkowa!<br></li>
		<li>Zachęcamy do wpisywania intencji w sprawach doczesnych<b> do Księgi Jasnogórskiej, </b>która wyłożona jest na stoliku przy obrazie Matki Bożej Częstochowskiej – Królowej Polski. Dzisiaj o godz. 18.00 zostanie odprawiona Msza święta w tych intencjach. Po Mszy świętej Apel Jasnogórski.<br></li>
		<li>Nasza parafia organizuje dwie tematyczne wycieczki szlakiem Stolic Naddunajskich. W czerwcu odwiedzimy <b>Budapeszt w dniach 22-25 czerwca</b>. Natomiast we wrześniu zobaczymy <b>Bratysławę oraz Wiedeń w dniach 21-24 września</b>. Koszt każdego wyjazdu to 350 zł i 250 euro. <br> Zapisy w kancelarii lub u pilota nr tel. podany na plakacie. Zaliczka w wysokości 250 euro na wyjazd do Budapesztu do 30 kwietnia zaś na wyjazd do Bratysławy i Wiednia do 30 czerwca.<br></li>
		<li>Istnieje możliwość przekazania <b>darowizny na rzecz naszej parafii</b>, którą można odliczyć od podatku jako darowiznę na cele kultu religijnego w rozliczeniu rocznym PIT, wysokości 6% osiągniętego dochodu w roku. Darowiznę przekazujemy na konto bankowe parafii, które dostępne jest na stronę internetowej parafii <a href="http://www.kolbe.lublin.pl/">&nbsp;<br></a></li>
		<li><b>I zapowiedź przedmałżeńska</span></b>: <b>Mateusz Bancerz</b> z parafii Miłosierdzia Bożego&nbsp;w Puławach i <b>Anna Długosz</b> z parafii tutejszej.<br></span></li>
		<li>W minionym tygodniu z naszej parafii do wieczności odeszły: <b>+ Lidia Paszkowska i + dr Leokadia Grudzień</b></li>
		</ol> `
	},
	{
		id: 1,
		title: '26.02.2023 - I Niedziela Wielkiego Postu',
		date: '11.09.2022',
		text: `<ol>
		<li>Rozpoczął się święty okres <b>Wielkiego Postu</b>. Zachęcamy do praktykowania uczynków pokutnych: postu, modlitwy i jałmużny.<br></li>
		<li>Z jałmużny wielkopostnej zakupimy <b>żywność do paczek dla ubogich osób z parafii</b>. Chcemy dostarczyć żywność przez harcerzy i harcerki w dwóch terminach: <b>20 marca w uroczystość św. Józefa i 5 kwietnia w Wielką Środę.</b> Prosimy parafian o zgłaszanie adresów potrzebujących osób do naszej bazy danych. Bóg zapłać! &nbsp;<br></li>
		<li>Nasza <b>świątynia otwarta jest dla modlitwy</b> przez cały dzień. Obiekt jest <b>monitorowany przez system kamer</b>. W ostatnim czasie zdarzyły się w naszym kościele próby kradzieży. W związku z tym bardzo prosimy wszystkich przebywających na modlitwie o czujność i zwrócenie uwagi na osoby zachowujące się w sposób podejrzany. Taką sytuacje należy natychmiast zgłosić księżom na plebanię.<br></li>
		<li>Zapraszamy na nabożeństwa wielkopostne</span>: <b>Droga Krzyżowa</b> w piątki o godz. 17.00; <b>Gorzkie Żale&nbsp;</b>w niedziele Wielkiego Postu o godz. 17.30.<br> </li>
		<li><em></em><b>Ekstremalna Droga Krzyżowa</b> po raz dziewiąty wyjdzie z Lublina 17 marca. Msza św. rozpoczynająca EDK będzie sprawowana w Archikatedrze Lubelskiej o godz. 19.00 pod przewodnictwem Ks. Abp. Stanisława Budzika. Zapisy trwają na stronie internetowej edk.lublin.pl<br></li>
		<li><em></em>W tym tygodniu obchodzimy<b> I piątek i I sobotę marca</b> - nabożeństwo wynagradzające <b>Najświętszemu Sercu Pana Jezusa</b> i <b>Niepokalanemu Sercu Maryi</b> o godz. 17.00.  Wizytę u chorych w domach można zgłaszać w zakrystii po Mszy Świętej.<br></li>
		<li>W I piątek marca zapraszamy <b>kandydatów do bierzmowania</b> na nabożeństwo pokutne o godz. 17.00, spowiedź od godz. 17.15 i na Msze św. o godz. 18.00.Obecność obowiązkowa!<br></li>
		<li>Zachęcamy do wpisywania intencji w sprawach doczesnych<b> do Księgi Jasnogórskiej, </b>która wyłożona jest na stoliku przy obrazie Matki Bożej Częstochowskiej – Królowej Polski. Dzisiaj o godz. 18.00 zostanie odprawiona Msza święta w tych intencjach. Po Mszy świętej Apel Jasnogórski.<br></li>
		<li>Nasza parafia organizuje dwie tematyczne wycieczki szlakiem Stolic Naddunajskich. W czerwcu odwiedzimy <b>Budapeszt w dniach 22-25 czerwca</b>. Natomiast we wrześniu zobaczymy <b>Bratysławę oraz Wiedeń w dniach 21-24 września</b>. Koszt każdego wyjazdu to 350 zł i 250 euro. <br> Zapisy w kancelarii lub u pilota nr tel. podany na plakacie. Zaliczka w wysokości 250 euro na wyjazd do Budapesztu do 30 kwietnia zaś na wyjazd do Bratysławy i Wiednia do 30 czerwca.<br></li>
		<li>Istnieje możliwość przekazania <b>darowizny na rzecz naszej parafii</b>, którą można odliczyć od podatku jako darowiznę na cele kultu religijnego w rozliczeniu rocznym PIT, wysokości 6% osiągniętego dochodu w roku. Darowiznę przekazujemy na konto bankowe parafii, które dostępne jest na stronę internetowej parafii <a href="http://www.kolbe.lublin.pl/">&nbsp;<br></a></li>
		<li><b>I zapowiedź przedmałżeńska</span></b>: <b>Mateusz Bancerz</b> z parafii Miłosierdzia Bożego&nbsp;w Puławach i <b>Anna Długosz</b> z parafii tutejszej.<br></span></li>
		<li>W minionym tygodniu z naszej parafii do wieczności odeszły: <b>+ Lidia Paszkowska i + dr Leokadia Grudzień</b></li>
		</ol> `
	},
	{
		id: 0,
		title: '26.02.2023 - I Niedziela Wielkiego Postu',
		date: '11.09.2022',
		text: `<ol>
		<li>Rozpoczął się święty okres <b>Wielkiego Postu</b>. Zachęcamy do praktykowania uczynków pokutnych: postu, modlitwy i jałmużny.<br></li>
		<li>Z jałmużny wielkopostnej zakupimy <b>żywność do paczek dla ubogich osób z parafii</b>. Chcemy dostarczyć żywność przez harcerzy i harcerki w dwóch terminach: <b>20 marca w uroczystość św. Józefa i 5 kwietnia w Wielką Środę.</b> Prosimy parafian o zgłaszanie adresów potrzebujących osób do naszej bazy danych. Bóg zapłać! &nbsp;<br></li>
		<li>Nasza <b>świątynia otwarta jest dla modlitwy</b> przez cały dzień. Obiekt jest <b>monitorowany przez system kamer</b>. W ostatnim czasie zdarzyły się w naszym kościele próby kradzieży. W związku z tym bardzo prosimy wszystkich przebywających na modlitwie o czujność i zwrócenie uwagi na osoby zachowujące się w sposób podejrzany. Taką sytuacje należy natychmiast zgłosić księżom na plebanię.<br></li>
		<li>Zapraszamy na nabożeństwa wielkopostne</span>: <b>Droga Krzyżowa</b> w piątki o godz. 17.00; <b>Gorzkie Żale&nbsp;</b>w niedziele Wielkiego Postu o godz. 17.30.<br> </li>
		<li><em></em><b>Ekstremalna Droga Krzyżowa</b> po raz dziewiąty wyjdzie z Lublina 17 marca. Msza św. rozpoczynająca EDK będzie sprawowana w Archikatedrze Lubelskiej o godz. 19.00 pod przewodnictwem Ks. Abp. Stanisława Budzika. Zapisy trwają na stronie internetowej edk.lublin.pl<br></li>
		<li><em></em>W tym tygodniu obchodzimy<b> I piątek i I sobotę marca</b> - nabożeństwo wynagradzające <b>Najświętszemu Sercu Pana Jezusa</b> i <b>Niepokalanemu Sercu Maryi</b> o godz. 17.00.  Wizytę u chorych w domach można zgłaszać w zakrystii po Mszy Świętej.<br></li>
		<li>W I piątek marca zapraszamy <b>kandydatów do bierzmowania</b> na nabożeństwo pokutne o godz. 17.00, spowiedź od godz. 17.15 i na Msze św. o godz. 18.00.Obecność obowiązkowa!<br></li>
		<li>Zachęcamy do wpisywania intencji w sprawach doczesnych<b> do Księgi Jasnogórskiej, </b>która wyłożona jest na stoliku przy obrazie Matki Bożej Częstochowskiej – Królowej Polski. Dzisiaj o godz. 18.00 zostanie odprawiona Msza święta w tych intencjach. Po Mszy świętej Apel Jasnogórski.<br></li>
		<li>Nasza parafia organizuje dwie tematyczne wycieczki szlakiem Stolic Naddunajskich. W czerwcu odwiedzimy <b>Budapeszt w dniach 22-25 czerwca</b>. Natomiast we wrześniu zobaczymy <b>Bratysławę oraz Wiedeń w dniach 21-24 września</b>. Koszt każdego wyjazdu to 350 zł i 250 euro. <br> Zapisy w kancelarii lub u pilota nr tel. podany na plakacie. Zaliczka w wysokości 250 euro na wyjazd do Budapesztu do 30 kwietnia zaś na wyjazd do Bratysławy i Wiednia do 30 czerwca.<br></li>
		<li>Istnieje możliwość przekazania <b>darowizny na rzecz naszej parafii</b>, którą można odliczyć od podatku jako darowiznę na cele kultu religijnego w rozliczeniu rocznym PIT, wysokości 6% osiągniętego dochodu w roku. Darowiznę przekazujemy na konto bankowe parafii, które dostępne jest na stronę internetowej parafii <a href="http://www.kolbe.lublin.pl/">&nbsp;<br></a></li>
		<li><b>I zapowiedź przedmałżeńska</span></b>: <b>Mateusz Bancerz</b> z parafii Miłosierdzia Bożego&nbsp;w Puławach i <b>Anna Długosz</b> z parafii tutejszej.<br></span></li>
		<li>W minionym tygodniu z naszej parafii do wieczności odeszły: <b>+ Lidia Paszkowska i + dr Leokadia Grudzień</b></li>
		</ol> `
	}
];

export default projects;
