<script>
import feather from 'feather-icons';
import announcements from '../../data/announcements';

export default {
	data: () => {
		return {
			announcements
		};
	},
	computed: {
		filteredAnnouncements() {
			return this.getLastThreeAnnouncements();
		},
	},
	methods: {
		getLastThreeAnnouncements() {
			let startId = this.announcements.length - 3;
			
			return this.announcements.filter((el) => el.id >= startId);
		},
	},
	mounted() {
		feather.replace();
	},
};
</script>

<template>
	<div>
		<router-link 
		:to="`/ogloszenia/${announcement.id}`"
			v-for="announcement in filteredAnnouncements"
			class="w-full block ext-lg text-ternary-dark dark:text-ternary-light hover:underline"
			:key="announcement.id"
			:announcement="announcement"
		>
			{{ announcement.title }}
		</router-link>
	</div>
</template>

<style scoped></style>
