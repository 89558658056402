<script>
import { useRoute } from 'vue-router';

export default {
	name: 'Zdjęcie',
	computed: {
		image () {
			const route = useRoute();
			return route.params.img;
		},
	},
};
</script>

<template>
	<div class="mx-auto text-center">
		<img class="mx-auto" :src="image"/>
		<!-- Load more projects button -->
		<div class="mt-10 sm:mt-10 flex justify-center">
			<a class="font-general-medium flex items-center px-6 py-3 rounded-lg shadow-lg hover:shadow-xl bg-indigo-500 hover:bg-indigo-600 focus:ring-1 focus:ring-indigo-900 text-white text-lg sm:text-xl duration-300 cursor-pointer"
				@click="$router.go(-1)">Powrót</a>
		</div>
	</div>
</template>

<style scoped></style>
