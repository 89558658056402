<script>
import feather from 'feather-icons';

export default {
	name: 'Home',
	data: () => {
		return {
			theme: '',
		};
	},
	created() {
		this.theme = localStorage.getItem('theme') || 'light';
	},
	mounted() {
		feather.replace();
		this.theme = localStorage.getItem('theme') || 'light';
	},
	updated() {
		feather.replace();
	},
	methods: {},
};
</script>

<template>
	<section
		class="flex flex-col sm:justify-between items-center sm:flex-row"
	>
		<!-- Banner left contents -->
		<div class="w-full md:w-3/3">
		</div>
	</section>
</template>

<style scoped>
	section{
		min-height: 400px;
		background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("@/assets/images/Wnetrze_kosciola_Branice_5556274.jpg");
		background-position: center 40%;
		background-size: cover;
	}
	@media (max-width: 750px){
		section{
			min-height: 300px;
		}
	}
	@media (max-width: 550px){
		section{
			background-size: 600px;
		}
	}
</style>
