<script>
import AppBanner from '@/components/shared/AppBanner';
import OParafii from '@/components/shared/OParafii';
import ProjectsWidget from '../components/projects/ProjectsWidget.vue';
import Button from '../components/reusable/Button.vue';

export default {
	name: 'Home',
	components: {
		AppBanner,
		OParafii,
		ProjectsWidget,
		Button,
	},
};
</script>

<template>
	<div class="mx-auto">
		<!-- Banner -->
		<AppBanner class="mb-5 sm:mb-8" />

		<OParafii class="mb-5 sm:mb-8" />

		<!-- Projects -->
		<ProjectsWidget />

		<!-- Load more projects button -->
		<div class="mt-10 sm:mt-20 flex justify-center">
			<router-link
				to="/wydarzenia"
				class="font-general-medium flex items-center px-6 py-3 rounded-lg shadow-lg hover:shadow-xl bg-indigo-500 hover:bg-indigo-600 focus:ring-1 focus:ring-indigo-900 text-white text-lg sm:text-xl duration-300"
				aria-label="More Projects"
			>
				<Button title="Więcej" />
			</router-link>
		</div>
	</div>
</template>

<style scoped></style>
