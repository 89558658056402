<script>
export default {
	props: ['showModal', 'isOpen'],
};
</script>

<template>
	<!-- Header links -->
	<div
		:class="isOpen ? 'block' : 'hidden'"
		class="m-0 sm:ml-4 mt-5 sm:mt-3 sm:flex p-5 sm:p-0 justify-center items-center shadow-lg sm:shadow-none"
	>
		<router-link
			to="/"
			class="font-general-medium block text-left text-lg font-medium dark:text-primary-dark text-ternary-light dark:hover:text-indigo-600 hover:text-indigo-300  sm:mx-3 mb-2 sm:py-2 "
			aria-label="Home"
			>Strona główna</router-link
		>
		<router-link
			to="/wydarzenia"
			class="font-general-medium block text-left text-lg font-medium dark:text-primary-dark text-ternary-light dark:hover:text-indigo-600 hover:text-indigo-300  sm:mx-3 mb-2 sm:py-2 "
			aria-label="Wydarzenia"
			>Wydarzenia</router-link
		>
		<router-link
			to="/o-parafii"
			class="font-general-medium block text-left text-lg font-medium dark:text-primary-dark text-ternary-light dark:hover:text-indigo-600 hover:text-indigo-300  sm:mx-3 mb-2 sm:py-2 "
			aria-label="O parafii"
			>O parafii</router-link
		>
		<router-link
			to="/kontakt"
			class="font-general-medium block text-left text-lg font-medium dark:text-primary-dark text-ternary-light dark:hover:text-indigo-600 hover:text-indigo-300  sm:mx-3 mb-2 sm:py-2 "
			aria-label="Kontakt"
			>Kontakt</router-link
		>
	</div>
</template>

<style scoped>
	@media (max-width: 750px){
		a{
			font-size: 14px;
		}
	}
</style>
