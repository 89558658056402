<script>
import feather from 'feather-icons';
import AnnouncementList from '../../components/announcements/AnnouncementList.vue';

export default {
	name: 'Home',
	data: () => {
		return {
			theme: '',
		};
	},
	components: {
		AnnouncementList
	},
	created() {
		this.theme = localStorage.getItem('theme') || 'light';
	},
	mounted() {
		feather.replace();
		this.theme = localStorage.getItem('theme') || 'light';
	},
	updated() {
		feather.replace();
	},
	methods: {},
};
</script>

<template>
	<section
		class=" container mx-auto"
	>
		<div class="text-center">
			<img class="delimiter pt-10 mx-auto" src="@/assets/images/delimiter.png"/>
			<h2
				class="pt-10 font-general-semibold text-2xl sm:text-4xl font-semibold text-ternary-dark dark:text-ternary-light"
			>
				Parafia Świętej Rodziny w Branicach
			</h2>
		</div>
		<div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 sm:mt-6 sm:gap-10 pt-0 sm:pt-14">
			<div class="text-left dark:text-ternary-light">
				<h2 class="font-general-semibold text-xl sm:text-xl font-semibold mb-4 mt-10 sm:mt-4 text-ternary-dark dark:text-ternary-light">
					Kancelaria parafialna:
				</h2>
				<p>Czynna w dni powszednie od pn do pt:<br/>
					8:15-9:00 i 16:00-17:30<br/>
					tel. 0-81-744-56-35<br/>
					<br/>
					<b>Konto parafialne:</b><br/>
					14 1240 2496 1111 0000 3759 6413
				</p>
			</div>
			<div class="text-left dark:text-ternary-light">
				<h2 class="font-general-semibold text-xl sm:text-xl font-semibold mb-4 mt-10 sm:mt-4 text-ternary-dark dark:text-ternary-light">
					Ogłoszenia parafialne:
				</h2>
				<AnnouncementList />
			</div>
			<div class="text-left dark:text-ternary-light">
				<h2 class="font-general-semibold text-xl sm:text-xl font-semibold mb-4 mt-10 sm:mt-4 text-ternary-dark dark:text-ternary-light">
					Porządek Mszy Świętych:
				</h2>
				<p><b>niedziele:</b><br/>
					7:00, 8:30, 10:00, 11:30 (dla dzieci), 13:00 (z wykluczeniem wakacji), 18:00 (dla młodzieży)
					<br/>
					<b>dni powszednie i święta nienakazane:</b><br/>
					7:00, 18:00
				</p>
			</div>
		</div>
			
	</section>
</template>

<style scoped>
</style>
